import { useRef, useEffect } from 'react';
import { MeshStandardMaterial } from 'three';

import { create } from 'zustand';

export const useInterpreterModel = create(set => ({
  model: null,
  setModel: (model) => set(state => ({ model }))
}));

export default function Model(props) {
  const modelRef = useRef();
  const {
    showModel,
    modelScale = 1,
    modelColor,
  } = props;

  const {
    model
  } = useInterpreterModel();

  useEffect(() => {
    if(model && model.children && model.children[0] && model.children[0].material) {
      model.children[0].material = new MeshStandardMaterial({ color: modelColor, roughness: .4, metalness: .2 });
    }
  }, [ model, modelColor ]);

  if(model) {
    return <primitive ref={modelRef} object={model} visible={showModel} scale={[ modelScale, modelScale, modelScale ]}/>
  } else {
    return null;
  }
};
