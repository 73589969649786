import { lookUpCommandDescription } from '../gcode/commands';
import * as types from '../constants/messages';

export function OWordsNotSupported() {
  return {
    message: "O words are not currently supported in the simulator. This line is being ignored, which could significantly change the behavior of the program.",
    type: types.OWORD_NOT_SUPPORTED,
    severity: types.WARNING
  };
}

export function RadiusFormatArc() {
  const message = "Radius Format Arcs are not recommended. Consider using Center Format Arcs instead.";
  return {
    message,
    type: types.RADIUS_FORMAT_ARC,
    severity: types.WARNING
  }
}

export function RadiusDiffers(r0,r1) {
  const message = `Radius to end of arc differs from radius to start: radius start = ${r0}, radius end = ${r1}, difference = ${r0-r1}`;
  return {
    message,
    type: types.RADIUS_DIFFERS,
    severity: types.ERROR
  }
}
export function NonIntegerToolNumber(number) {
  const message = "Can't set tool number to non integer value: " + number;
  return {
    message,
    type: types.NON_INTEGER_TOOL_NUMBER,
    severity: types.ERROR
  };
}

export function ToolNumberLessThanZero() {
  const message = "Tool number must be greater than or equal to 0. ";
  return {
    message,
    type: types.TOOL_NUMBER_LESS_THAN_ZERO,
    severity: types.ERROR
  };
}

export function LargeToolNumber() {
  const message = "Tool number must be less than or equal to 55. ";
  return {
    message,
    type: types.LARGE_TOOL_NUMBER,
    severity: types.ERROR
  };
}

export function ToolNumberEqualToZero() {
  const message = "Tool number 0 is not recommended as its length cannot be changed. Tool 0's\n" +
                  "tool length offset is 0, which represents a tool that extends all the way \n" +
                  "to the center of rotation when in the home position and has a diameter of 0.";
  return {
    message,
    type: types.TOOL_NUMBER_EQUAL_TO_ZERO,
    severity: types.WARNING
  };
}

export function MultipleCommandsInModalGroup(codes, group) {
  const message = "Can't run multiple commands in the same modal group on the same line. Codes " + codes.slice(0,codes.length-1).join(", ") + " and " + codes[codes.length-1] + " are in modal group " + group + ".";
  return {
    message,
    codes,
    group,
    type: types.MULTIPLE_COMMANDS_IN_MODAL_GROUP,
    severity: types.ERROR
  };
};

export function MultipleCommandsWithPositionalArgs(codes) {
  const message = "Can't run multiple commands with positional arguments on the same line. Codes " + codes.slice(0,codes.length-1).join(", ") + " and " + codes[codes.length-1] + " use positional arguments.";
  return {
    message,
    codes,
    type: types.MULTIPLE_COMMANDS_WITH_POSITIONAL_ARGS,
    severity: types.ERROR
  };
};

export function MinimumLimitError(axis, amount) {
  const message = axis + " axis exceeded minimum limit by " + amount.toFixed(4) + ".";
  return {
    message,
    axis,
    amount,
    type: types.MINIMUM_LIMIT,
    severity: types.ERROR
  }
}

export function MaximumLimitError(axis, amount) {
  const message = axis + " axis exceeded maximum limit by " + amount.toFixed(4) + ".";
  return {
    message,
    axis,
    amount,
    type: types.MAXIMUM_LIMIT,
    severity: types.ERROR
  }
}

export function UnimplementedCode(code, usage) {
  const description = usage || lookUpCommandDescription(code);
  const message = "Ignoring " + code + " as it is currently unimplemented in the simulator. Intended usage: " + description;
  return {
    message,
    description,
    severity: types.WARNING,
    type: types.UNIMPLEMENTED,
    code
  };
};

export function UnrecognizedCode(code) {
  const message = "Unrecognized G or M code: " + code;
  return {
    message,
    severity: types.ERROR,
    type: types.UNRECOGNIZED,
    code
  };
};

export function InverseTimeModeRequiresFeedRate() {
  const message = "When in inverse time mode (G93), a feed rate must be specified on every feed rate move."
  return {
    message,
    severity: types.ERROR,
    type: types.INVERSE_TIME_REQUIRES_FEED_RATE
  };
};

export function FeedRateModeResetsFeedRate() {
  const message = "When changing feed rate modes (G93,G94,G95) and performing a feed rate move on the same line, a feed rate must be specified as the feed rate is reset to 0.";
  return {
    message,
    severity: types.ERROR,
    type: types.FEED_RATE_MODE_RESETS_FEED_RATE
  };
}

export function InverseTimeResetsFeedRate() {
  const message = "When changing from inverse time mode (G93) to units per minute mode (G94), a feed rate must be specified as inverse time mode resets the feed rate to 0 after every motion move."
  return {
    message,
    severity: types.ERROR,
    type: types.INVERSE_TIME_REQUIRES_FEED_RATE
  };
};

export function ZeroFeedRate() {
  const message = "Feed rate must be greater than 0 for linear or arc moves."
  return {
    message,
    severity: types.ERROR,
    type: types.ZERO_FEED_RATE
  };
};

// This message occurs when a G43 command is interpretted, indicating a tool change. M6 is actually
// the tool change command, but since G43 is what affects the tool length offset, it's ultimately
// what determines what was actually loaded.  It is good practice, when using LinuxCNC/MachineKit
// to use G43 without an argument to ensure the M6 and G43 agree.
// toolNumber is the number set in GCode i.e. G43 H[toolNumber]
// A G43 without a toolNumber argument will use the toolNumber of the last tool loaded with M6.
export function SetToolLengthOffset(toolNumber, toolLengthOffset, toolDiameter, toolHolder) {
  const message = "Set tool length offset to " + toolLengthOffset.toFixed(4) + " which is what is stored under tool " + toolNumber + ".";
  return {
    message,
    toolNumber,
    toolLengthOffset,
    toolDiameter,
    toolHolder,
    severity: types.INFO,
    type: types.SET_TOOL_LENGTH_OFFSET
  };
}

const codeMap = {
  1: "G54",
  2: "G55",
  3: "G56",
  4: "G57",
  5: "G58",
  6: "G59",
  7: "G59.1",
  8: "G59.2",
  9: "G59.3"
};

export function SetWorkCoordinateSystem(wcs, workOffsets, labelOrder) {
  const code = codeMap[wcs];
  const message = "Set work coordinate system to " + code + " (WCS " + wcs + ").\n" +
                  labelOrder.map((k) => k + ": " + workOffsets[k].toFixed(4)).join("\n");
  return {
    message,
    code,
    wcs,
    workOffsets,
    labelOrder,
    severity: types.INFO,
    type: types.SET_WORK_COORDINATE_SYSTEM
  };
}

export function SetWorkOffsets(wcs, workOffsets, labelOrder) {
  const code = codeMap[wcs];
  const message = "Set work offsets for " + code + " (WCS " + wcs + ") via G10 L2.\n" +
                  "This isn't commonly used in a G code program. Usually, G5X offsets\n" +
                  "are set ahead of time in the Setup tab of Kinetic Control. They can\n" +
                  "also be set in the simulator by going to the Summary tab if a G5x\n" +
                  "code is used in your program (where x is 1-9, 9.1, 9.2, or 9.3).\n" +
                  "Note that if the work offsets are set in the Setup tab or the\n" +
                  "Summary tab they will be overwritten with the values set on this\n" +
                  "line.\n" +
                  labelOrder.map((k) => k + ": " + workOffsets[k].toFixed(4)).join("\n");
  return {
    message,
    code,
    wcs,
    workOffsets,
    labelOrder,
    severity: types.WARNING,
    type: types.SET_WORK_OFFSETS
  };
}

export function ComputedDynamicWorkOffsets(wcs, currentWcs, computedOffsets, labelOrder) {
  const code = codeMap[wcs];
  const currentCode = codeMap[currentWcs];
  const message = "Computed dynamic work offsets for " + code + " (WCS " + wcs + ") from " + currentCode + " (WCS " + currentWcs + ").\n" +
                  labelOrder.map((k) => k + ": " + computedOffsets[k].toFixed(4)).join("\n");
  return {
    message,
    code,
    wcs,
    currentWcs,
    currentCode,
    computedOffsets,
    severity: types.INFO,
    type: types.COMPUTED_DYNAMIC_WORK_OFFSETS
  };
}

// M6 is the load tool command, which could accept a different tool than the one set
// in a following G43 command. G43 is what ultimately affects how the machine behaves,
// but the simulator uses what was loaded with M6 to determine which tool is loaded, so
// if they differ, you may get unexpected results.
export function ToolLengthOffsetCommandDiffersFromLoadedTool(g43ToolNum, m6ToolNum) {
  const message = "Tool specified by G43, T" + g43ToolNum + ", differs from last tool loaded with M6, T" + m6ToolNum + ". The simulator uses the last tool loaded with M6 to determine which tool to show, so your tool path may vary from what you would expect.";
  return {
    message,
    g43ToolNum,
    m6ToolNum,
    severity: types.WARNING,
    type: types.TOOL_LENGTH_OFFSET_COMMAND_DIFFERS_FROM_LOADED_TOOL
  };
}

const unitCodesMap = {
  G20: "inches",
  G21: "millimeters"
};
export function SetUnits(code) {
  const message = "Set units to " + unitCodesMap[code] + ".";
  return {
    message,
    code,
    severity: types.INFO,
    type: types.SET_UNITS
  };
}

export function AddLineNumber(message, line) {
  return {
    ...message,
    line
  }
}

