import { connect } from 'react-redux';

import OtherOptions from '../components/OtherOptions';
import actions from '../actions';
import { programUnits } from '../selectors';
import { inchesToUnits, unitsToInches } from '../util/units';

import { InterpreterCache } from '../gcode/interpreter-cache';

export default connect(
  (state, ownProps) => {
    const units =  programUnits(state);
    const offset = inchesToUnits(state.viewer3d.offset, units);

    return {
      modelShown: state.viewer3d.showModel,
      modelLoaded: state.viewer3d.modelLoaded,
      modelScale: state.viewer3d.modelScale,
      modelColor: state.viewer3d.modelColor,
      modelOffsetType: state.viewer3d.modelOffsetType,
      modelOffsetIndex: state.viewer3d.modelOffsetIndex,

      currentLine: state.interpreter.currentLine,

      showBetweenLines: state.viewer3d.showBetweenLines,
      showTimeSlice: state.viewer3d.showTimeSlice,
      showPastOnly: state.viewer3d.showPastOnly,
      showFutureOnly: state.viewer3d.showFutureOnly,

      minLine: state.viewer3d.minLine,
      maxLine: Math.min(state.viewer3d.maxLine, state.interpreter.numLines),
      automaticallyUpdateLines: state.viewer3d.automaticallyUpdateLines,
      isolateSlider: state.viewer3d.isolateSlider,

      timeSlice: state.viewer3d.timeSlice,

      rapidColor: state.viewer3d.rapidColor,
      feedColor: state.viewer3d.feedColor,

      alwaysShowControlsOverlay: state.ui.controlsOverlay.alwaysVisible,
      offset,
      units
    };
  },
  (dispatch, ownProps) => ({
    showModel: () => dispatch(actions.viewer3d.showModel()),
    hideModel: () => dispatch(actions.viewer3d.hideModel()),
    setModelScale: (scale) => dispatch(actions.viewer3d.setModelScale(scale)),
    setModelColor: (color) => dispatch(actions.viewer3d.setModelColor(color)),
    setModelOffsetType: (type) => dispatch(actions.viewer3d.setModelOffsetType(type)),
    setModelOffsetIndex: (index) => dispatch(actions.viewer3d.setModelOffsetIndex(index)),

    setRapidColor: (color) => dispatch(actions.viewer3d.setRapidColor(color)),
    setFeedColor: (color) => dispatch(actions.viewer3d.setFeedColor(color)),
    setMinLine: (line) => dispatch(actions.viewer3d.setMinLine(line)),
    setMaxLine: (line) => dispatch(actions.viewer3d.setMaxLine(line)),
    setAutomaticallyUpdateLines: (autoUpdate) => dispatch(actions.viewer3d.setAutomaticallyUpdateLines(autoUpdate)),
    setIsolateSlider: (isolate) => dispatch(actions.viewer3d.setIsolateSlider(isolate)),
    setTimeSlice: (time) => dispatch(actions.viewer3d.setTimeSlice(time)),
    setShowBetweenLines: (show) => dispatch(actions.viewer3d.setShowBetweenLines(show)),
    setShowTimeSlice: (show) => dispatch(actions.viewer3d.setShowTimeSlice(show)),
    setShowPastOnly: (show) => dispatch(actions.viewer3d.setShowPastOnly(show)),
    setShowFutureOnly: (show) => dispatch(actions.viewer3d.setShowFutureOnly(show)),
    setAlwaysShowControlsOverlay: (show) => dispatch(actions.ui.controlsOverlay.setAlwaysVisible(show)),
    setOffset: (offsetUnits, units) => {
      const offsetInches = unitsToInches(offsetUnits, units);
      dispatch(actions.viewer3d.setOffset(offsetInches))
    },
    setCurrentToolPath: (currentLine) => {
      const interpreterCache = InterpreterCache.getInstance();
      const { min, max } = interpreterCache.getCurrentToolPathRange(currentLine);
      dispatch(actions.viewer3d.setMinLine(min));
      dispatch(actions.viewer3d.setMaxLine(max));
    },
    resetBetweenLines: () => {
      dispatch(actions.viewer3d.setMinLine(0));
      dispatch(actions.viewer3d.setMaxLine(999999999));
    },
    runReplaceSelectionWithTcpc: () => dispatch(actions.interpreter.runReplaceSelectionWithTcpc())
  })
)(OtherOptions);
