import React, { useEffect, useState, useCallback } from 'react';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import TextFieldWithButton from './TextFieldWithButton';
import CurrentLineIcon from '@mui/icons-material/FormatIndentDecrease';
import Slider from '@mui/material/Slider';
import { EPS } from '../constants';
import { FIRST_G5X_ENCOUNTERED, USER_CHOSEN_G5X, CENTER_OF_ROTATION } from '../constants/viewer3d';
import ColorPicker from './ColorPicker';

import { 
  useCameraControls, 
  selectSetFilter, 
  selectFilter 
} from './viewer3d/CameraControls';


const PREFIX = "OtherOptions";

const classes = {
  label: `${PREFIX}-label`,
  marginLeft: `${PREFIX}-marginLeft`,
  marginTop: `${PREFIX}-marginTop`,
  toolPathButton: `${PREFIX}-toolPathButton`
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.label}`]: {
    marginTop: 0
  },
  [`& .${classes.marginLeft}`]: {
    marginLeft: "10px"
  },
  [`& .${classes.marginTop}`]: {
    marginTop: "5px"
  },
  [`& .${classes.toolPathButton}`]: {
    marginTop: theme.spacing(1)
  }
}));

function OtherOptions(props) {
  const {
    showAdvanced=false,
    modelShown,
    modelLoaded,
    modelColor,
    showModel,
    hideModel,
    modelScale,
    modelOffsetType,
    modelOffsetIndex,
    setModelScale,

    currentLine,

    showBetweenLines,
    showTimeSlice,
    showPastOnly,
    showFutureOnly,
    minLine,
    maxLine,
    automaticallyUpdateLines,
    isolateSlider,
    timeSlice,
    rapidColor,
    feedColor,
    offset,

    setModelColor,
    setModelOffsetType,
    setModelOffsetIndex,

    setShowBetweenLines,
    setShowTimeSlice,
    setShowPastOnly,
    setShowFutureOnly,
    setMinLine,
    setMaxLine,
    setAutomaticallyUpdateLines,
    setIsolateSlider,
    setTimeSlice,
    setRapidColor,
    setFeedColor,
    setOffset,

    setCurrentToolPath,
    resetBetweenLines,

    alwaysShowControlsOverlay,
    setAlwaysShowControlsOverlay,

    units,
    runReplaceSelectionWithTcpc
  } = props;

  const handleToggleAlwaysShow = useCallback(() => {
    setAlwaysShowControlsOverlay(!alwaysShowControlsOverlay);
  }, [ alwaysShowControlsOverlay, setAlwaysShowControlsOverlay ]);

  const handleSetMM = useCallback(() => {
    setModelScale(1./25.4);
  }, [ setModelScale ]);

  const handleSetIn = useCallback(() => {
    setModelScale(1);
  }, [ setModelScale ]);

  const handleToggleModel = useCallback(() => {
    if(modelShown) {
      hideModel();
    } else {
      showModel();
    }
  }, [ modelShown, showModel, hideModel ]);

  const setFilter = useCameraControls(selectSetFilter);
  const filter = useCameraControls(selectFilter);

  const handleToggleSmoothCameraTransitions = useCallback(() => {
    if(filter === 1) {
      setFilter(.1);
    } else {
      setFilter(1);
    }
  }, [ filter, setFilter ]);

  const handleSetFilter = useCallback((e,v) => {
    setFilter(v);
  }, [ setFilter ]);

  const handleToggleShowBetweenLines = useCallback(() => {
    setShowBetweenLines(!showBetweenLines);
  }, [ showBetweenLines, setShowBetweenLines ]);

  const handleToggleShowTimeSlice = useCallback(() => {
    setShowTimeSlice(!showTimeSlice);
  }, [ showTimeSlice, setShowTimeSlice ]);

  const handleToggleAutomaticallyUpdateLines = useCallback(() => {
    setAutomaticallyUpdateLines(!automaticallyUpdateLines);
  }, [ automaticallyUpdateLines, setAutomaticallyUpdateLines ]);

  const handleToggleIsolateSlider = useCallback(() => {
    setIsolateSlider(!isolateSlider);
  }, [ isolateSlider, setIsolateSlider ]);

  const handleToggleShowPastOnly = useCallback(() => {
    setShowPastOnly(!showPastOnly);
  }, [ showPastOnly, setShowPastOnly ]);

  const handleToggleShowFutureOnly = useCallback(() => {
    setShowFutureOnly(!showFutureOnly);
  }, [ showFutureOnly, setShowFutureOnly ]);

  const handleSetMinLineToCurrent = useCallback((e) => {
    setMinLine(currentLine);
  }, [ setMinLine, currentLine ]);
  const handleSetMaxLineToCurrent = useCallback((e) => {
    setMaxLine(currentLine);
  }, [ setMaxLine, currentLine ]);

  const handleChangeMinLine = useCallback((e) => {
    try {
      const l = Math.abs(parseInt(e.target.value));
      if(!isNaN(l)) {
        setMinLine(l-1);
      }
    } catch {}
  }, [ setMinLine ]);

  const [ offsetValue, setOffsetValue ] = useState(offset);
  const [ offsetFocused, setOffsetFocused ] = useState(false);
  useEffect(() => {
    if(!offsetFocused) {
      setOffsetValue(Math.round(offset*10000)/10000);
    }
  }, [ offsetFocused, offset, setOffsetValue ]);
  const handleFocusOffset = useCallback((e) => {
    setOffsetFocused(true);
  }, [ setOffsetFocused ]);
  const handleBlurOffset = useCallback((e) => {
    setOffsetValue(Math.round(offset*10000)/10000);
    setOffsetFocused(false);
  }, [ offset, setOffsetValue, setOffsetFocused ]);
  const handleChangeOffset = useCallback((e) => {
    setOffsetValue(e.target.value);
    try {
      const l = parseFloat(e.target.value);
      if(!isNaN(l)) {
        setOffset(l, units);
      }
    } catch {}
  }, [ units, setOffset ]);

  const handleChangeRapidColor = useCallback((e) => {
    setRapidColor(e.value);
  }, [ setRapidColor ]);

  const handleChangeModelColor = useCallback((e) => {
    setModelColor(e.value);
  }, [ setModelColor ]);

  const handleChangeFeedColor = useCallback((e) => {
    setFeedColor(e.value);
  }, [ setFeedColor ]);

  const handleChangeMaxLine = useCallback((e) => {
    try {
      const l = Math.abs(parseInt(e.target.value));
      if(!isNaN(l)) {
        setMaxLine(l-1);
      }
    } catch {}
  }, [ setMaxLine ]);

  const handleChangeTimeSlice = useCallback((e) => {
    try {
      const l = Math.abs(parseInt(e.target.value));
      if(!isNaN(l)) {
        setTimeSlice(l);
      }
    } catch {}
  }, [ setTimeSlice ]);

  const handleSetFirstEncountered = useCallback(() => {
    setModelOffsetType(FIRST_G5X_ENCOUNTERED);
  }, [ setModelOffsetType ]);
  const handleSetCenterOfRotation = useCallback(() => {
    setModelOffsetType(CENTER_OF_ROTATION);
  }, [ setModelOffsetType ]);
  const handleSetUserChosen = useCallback(() => {
    setModelOffsetType(USER_CHOSEN_G5X);
  }, [ setModelOffsetType ]);

  const handleChangeModelOffsetIndex = useCallback((e) => {
    setModelOffsetIndex(e.target.value);
  }, [ setModelOffsetIndex ]);


  return (<StyledDiv>
    <FormLabel component="legend">Back Plot</FormLabel>
    <FormGroup>
      <Tooltip disableInteractive title="Only show the back plot between the specified lines numbers in the program. After selecting a line in the G code editor, or scrubbing to a location in your program using the playback slider, use the 'Insert current line' button to populate the From or To field."><FormControlLabel control={<Checkbox checked={showBetweenLines} onClick={handleToggleShowBetweenLines}/>} label="Show between lines"/></Tooltip>
      <TextFieldWithButton 
        disabled={!showBetweenLines} 
        buttonContent={<Tooltip disableInteractive title="Insert current line"><CurrentLineIcon/></Tooltip>} 
        onClick={handleSetMinLineToCurrent}
        value={minLine+1}
        onChange={handleChangeMinLine}
        label="From"
      />
      <TextFieldWithButton 
        disabled={!showBetweenLines} 
        buttonContent={<Tooltip disableInteractive title="Insert current line"><CurrentLineIcon/></Tooltip>} 
        onClick={handleSetMaxLineToCurrent}
        value={maxLine+1}
        onChange={handleChangeMaxLine}
        label="To"
      />

      <Button color="inherit" variant="contained" classes={{ root: classes.toolPathButton }} onClick={() => setCurrentToolPath(currentLine)} disabled={!showBetweenLines}>Set current tool path</Button>
      <Button color="inherit" variant="contained" classes={{ root: classes.toolPathButton }} onClick={() => resetBetweenLines()} disabled={!showBetweenLines}>Set whole file</Button>

      <Tooltip disableInteractive title="Automatically update the From and To lines to span the currently active tool path."><FormControlLabel control={<Checkbox disabled={!showBetweenLines} checked={automaticallyUpdateLines} onClick={handleToggleAutomaticallyUpdateLines}/>} label="Automatically update lines"/></Tooltip>
      <Tooltip disableInteractive title="Use the full length of the playback slider to scrub through only the specified lines of the program. This can be helpful to debug portions of long programs."><FormControlLabel control={<Checkbox disabled={!showBetweenLines} checked={isolateSlider} onClick={handleToggleIsolateSlider}/>} label="Isolate Playback Slider"/></Tooltip>
      <Tooltip disableInteractive title="Show only the portion of the back plot that is within +/- the specified number of seconds of the current time specified in the playback slider. This gives a moving window, which can be helpful to see a localized portion of a long running program."><FormControlLabel control={<Checkbox checked={showTimeSlice} onClick={handleToggleShowTimeSlice}/>} label="Show time window"/></Tooltip>
      <TextFieldWithButton 
        disabled={!showTimeSlice} 
        value={timeSlice}
        onChange={handleChangeTimeSlice}
        label="Seconds"
      />
      <Tooltip disableInteractive title="Plot only up to the current time specified in the playback slider. This can be helpful to watch the program unfold over time, rather than seeing the whole plot at once."><FormControlLabel control={<Checkbox checked={showPastOnly} onClick={handleToggleShowPastOnly}/>} label="Show past only"/></Tooltip>
      <Tooltip disableInteractive title="Plot only after the current time specified in the playback slider. This can be helpful to know what is coming up in the program without cluttering the display with what happened previously."><FormControlLabel control={<Checkbox checked={showFutureOnly} onClick={handleToggleShowFutureOnly}/>} label="Show future only"/></Tooltip>
      <Tooltip disableInteractive placement="top" title="Color of the plot during rapid movements (G0 commands)."><FormControlLabel control={<ColorPicker value={rapidColor} onChange={handleChangeRapidColor}/>} label="Rapid Color"/></Tooltip>
      <Tooltip disableInteractive placement="top" title="Color of the plot during feed rate movements (G1, G2 and G3 commands)."><FormControlLabel control={<ColorPicker value={feedColor} onChange={handleChangeFeedColor}/>} label="Feed Color"/></Tooltip>
      <Tooltip disableInteractive title="Offset the plot along the tool by the specified amount. This can help display the plot on top of a loaded model or to help visualize a specified depth of cut.">
      <div>
      <TextFieldWithButton 
        value={offsetValue}
        textFieldProps={{ 
          onBlur: handleBlurOffset,
          onFocus: handleFocusOffset
        }}
        onChange={handleChangeOffset}
        label={"Plot Offset (" + (units || "IN") + ")"}
      />
      </div>
      </Tooltip>
    </FormGroup><br/>
  { modelLoaded ?
    <>
    <FormLabel component="legend">Model</FormLabel>
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={modelShown} onClick={handleToggleModel}/>} label="Show Model"/>
      Units
      <RadioGroup>
        <Tooltip disableInteractive placement="top" title="Model data is specified in millimeters."><FormControlLabel control={<Radio checked={Math.abs(modelScale-1.0/25.4) < EPS} onClick={handleSetMM}/>} label="mm"/></Tooltip>
        <Tooltip disableInteractive placement="top" title="Modle data is specified in inches."><FormControlLabel control={<Radio checked={modelScale === 1} onClick={handleSetIn}/>} label="in"/></Tooltip>
      </RadioGroup>
      Model Parent
      <RadioGroup>
        <Tooltip disableInteractive title="Offset the model by the first G5X (WCS) offset coordinate used in the program."><FormControlLabel control={<Radio checked={modelOffsetType === FIRST_G5X_ENCOUNTERED} onClick={handleSetFirstEncountered}/>} label="First G5X encountered"/></Tooltip>
        <Tooltip disableInteractive title="The origin of the model is the center of rotation of the machine."><FormControlLabel control={<Radio checked={modelOffsetType === CENTER_OF_ROTATION} onClick={handleSetCenterOfRotation}/>} label="Center of rotation"/></Tooltip>
        <Tooltip disableInteractive title="Offset the model by the G5X (WCS) offset specified below."><FormControlLabel control={<Radio checked={modelOffsetType === USER_CHOSEN_G5X} onClick={handleSetUserChosen}/>} label="Manually Select G5X"/></Tooltip>
      </RadioGroup>
      <Select value={modelOffsetIndex} disabled={modelOffsetType !== USER_CHOSEN_G5X} onChange={handleChangeModelOffsetIndex}>
        <MenuItem value={1}>G54 (1)</MenuItem>
        <MenuItem value={2}>G55 (2)</MenuItem>
        <MenuItem value={3}>G56 (3)</MenuItem>
        <MenuItem value={4}>G57 (4)</MenuItem>
        <MenuItem value={5}>G58 (5)</MenuItem>
        <MenuItem value={6}>G59 (6)</MenuItem>
        <MenuItem value={7}>G59.1 (7)</MenuItem>
        <MenuItem value={8}>G59.2 (8)</MenuItem>
        <MenuItem value={9}>G59.3 (9)</MenuItem>
      </Select>
      <Tooltip disableInteractive placement="top" title="Color of the model."><FormControlLabel control={<ColorPicker value={modelColor} onChange={handleChangeModelColor} hideTextfield disableAlpha/>} label="Model Color"/></Tooltip>
    </FormGroup>
    </>
    : null }

    <FormLabel component="legend">Camera</FormLabel>
    <FormGroup>
      <Tooltip disableInteractive title="Tranistion smoothly from one view to the next. Disabling this is useful when parented to the part or tool while watching the playback to avoid delays in camera travel."><FormControlLabel control={<Checkbox checked={filter !== 1} onClick={handleToggleSmoothCameraTransitions}/>} label="Smooth Camera Transitions"/></Tooltip>
      <FormControlLabel control={<Slider step={.001} disabled={filter === 1} value={filter} min={.001} max={.999} onChange={handleSetFilter}/>}/>
    </FormGroup>

    <FormLabel component="legend">Controls Overlay</FormLabel>
    <FormGroup>
      <Tooltip disableInteractive title="Always show the playback controls overlay, rather than hide it after a couple seconds."><FormControlLabel control={<Checkbox checked={alwaysShowControlsOverlay} onClick={handleToggleAlwaysShow}/>} label="Always show"/></Tooltip>
    </FormGroup>
    { showAdvanced ?
    <>
    <FormLabel component="legend">Advanced</FormLabel>
    <Tooltip disableInteractive title="Interpret G code program with selected lines replaced with baked out linear position data (any arc moves or rotary moves will be converted to multiple linear segments) using TCPC so it can be moved around using work offsets. This can be useful when a post processor outputs G code around the center of rotation, but you want to be able to mount your part in a different location and use work offsets to specify where it is. This is not guaranteed to match the behavior exactly, but can be helpful in certain situations. Note that this can dramatically increase the size of your program.">
    <Button color="inherit" variant="contained" classes={{ root: classes.toolPathButton }} onClick={runReplaceSelectionWithTcpc}>Replace selection with TCPC code</Button>
    </Tooltip>
    </>
    : null }
  </StyledDiv>);
}

export default OtherOptions;
