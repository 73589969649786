import React, { useMemo, useRef, useEffect, useState } from 'react'
import { useThree, useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei'
import { FilterClock, useCameraControls, Z, Y, selectSetUpAndForward, selectAddParent, selectRemoveParent, selectRadius } from './CameraControls';
import { checkLimits } from '../../machines';
import { SOLO } from '../../constants/machine-state/machine';
import { toolLength } from '../../machines/solo';

export default function Model({ 
  joints = [ 0, 0, 0, 0, 0 ],
  tool = null,
  toolHolder = "NONE",
  spindleAngle = 0,
  model = null,
  targetFPS = 120,
  modelOffsets = { X: 0, Y: 0, Z:0, A: 0, B:0, C: 0 },
  children,
  ...props 
}) {
  const group = useRef()
  const { scene } = useThree();
  const { nodes, materials } = useGLTF('/gltf/solo.glb')

  const baseRef = useRef();
  const spindleRef = useRef();
  const doorRef = useRef();
  const tableRef = useRef();
  const trunnionRef = useRef();
  const partRef = useRef();

  const addParent = useCameraControls(selectAddParent);
  const removeParent = useCameraControls(selectRemoveParent);
  const setUpAndForward = useCameraControls(selectSetUpAndForward);
  const radius = useCameraControls(selectRadius);

  const jointColors = useMemo(() => joints.map((joint, jointIndex) => checkLimits(SOLO, jointIndex, joint) ? 0x000000 : 0xaa0000 ), [ joints ]);

  const holder = nodes[toolHolder] ? <primitive object={nodes[toolHolder]} rotation-z={spindleAngle}/> : null;

  const [ clock ]  = useState(new FilterClock());

  useFrame(() => {
    const performFiltering = () => {
      const height = radius > 50 ? 0 : 20;
      const f = .98;
      if(doorRef.current) {
        doorRef.current.position.z = doorRef.current.position.z*f + (1-f)*height;
      }
  //    const opacity = radius > 30 ? 1 : 0;
  //    materials.DoorMaterial.transparent = true;
  //    materials.DoorClearAnnodize.transparent = true;
  //    materials.DoorMaterial.opacity = materials.DoorMaterial.opacity*f + (1-f)*opacity;
  //    materials.DoorClearAnnodize.opacity = materials.DoorMaterial.opacity*f + (1-f)*opacity;
    }
    let deltaT = clock.getElapsedTime();
    const singleFrameTime = 1000/targetFPS;
    while(deltaT > singleFrameTime) {
      performFiltering();
      deltaT -= singleFrameTime;
      clock.offsetStart(singleFrameTime);
    }
  });

  useEffect(() => {
    const intensity = 3;
    materials.EnclosureMaterial.envMap = scene.environment;
    materials.EnclosureMaterial.envMapIntensity = intensity;

    materials.DoorMaterial.envMap = scene.environment;
    materials.DoorMaterial.envMapIntensity = intensity;

    materials.DoorClearAnnodize.envMap = scene.environment;
    materials.DoorClearAnnodize.envMapIntensity = intensity;

    materials.ClearAnnodizedAluminum.envMap = scene.environment;
    materials.ClearAnnodizedAluminum.envMapIntensity = intensity;

    materials.SpindleStainlessSteel.envMap = scene.environment;
    materials.SpindleStainlessSteel.envMapIntensity = 1.5;

    materials.SpindleBlack.envMap = scene.environment;
    materials.SpindleBlack.envMapIntensity = intensity;

    materials.TrunnionMaterial.envMap = scene.environment;
    materials.TrunnionMaterial.envMapIntensity = intensity;

    materials.Mini90Material.envMap = scene.environment;
    materials.Mini90Material.envMapIntensity = 1.5;

    const base = { name: "Base", object: baseRef.current };
    const spindle = { name: "Spindle", object: spindleRef.current };
    const table = { name: "Table", object: tableRef.current };
    const part = { name: "Part", object: partRef.current };
    const trunnion = { name: "Trunnion", object: trunnionRef.current };

    addParent(base);
    addParent(spindle);
    addParent(table);
    addParent(trunnion);
    addParent(part);

    setUpAndForward(Z, Y);

    return () => {
      removeParent(base);
      removeParent(spindle);
      removeParent(table);
      removeParent(trunnion);
      removeParent(part);
    };
  }, [ addParent, materials, removeParent, scene, group, spindleRef, tableRef, trunnionRef, baseRef, setUpAndForward ]);

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.enclosure.geometry} material={materials.EnclosureMaterial} rotation-x={-Math.PI/2}>
        <group ref={baseRef}/>
        <spotLight
          intensity={0.5}
          angle={Math.PI / 8}
          penumbra={0.15}
          decay={2}
          position={[-50, -50, 50]}
          rotation={[Math.PI / 4, -0.62, -Math.PI / 6]}
        />
        <spotLight
          intensity={0.5}
          angle={Math.PI / 8}
          penumbra={0.15}
          decay={2}
          position={[56.27, -35.4, 4.86]}
          rotation={[Math.PI / 4, 0.62, Math.PI / 6]}
        />
        <spotLight
          intensity={0.07}
          angle={Math.PI / 8}
          penumbra={0.15}
          decay={2}
          position={[61.42, 41.42, 17.29]}
          rotation={[-Math.PI / 4, 0.62, 2.62]}
        />
        <spotLight
          intensity={0.07}
          angle={Math.PI / 8}
          penumbra={0.15}
          decay={2}
          position={[-24.92, 44.85, 50]}
          rotation={[-Math.PI / 4, -0.62, -2.62]}
        />
        <spotLight
          intensity={0.07}
          angle={Math.PI / 8}
          penumbra={0.15}
          decay={2}
          position={[-29.16, 69.99, -49.41]}
          rotation={[-2.36, -0.62, 2.62]}
        />
        <group visible={true} ref={doorRef}>
          <mesh geometry={nodes.door_1.geometry} material={materials.DoorMaterial} />
          <mesh geometry={nodes.door_2.geometry} material={materials.DoorClearAnnodize} />
        </group>
        <group position={[joints[0], joints[1], toolLength(joints[2])]} ref={spindleRef}>
          <mesh geometry={nodes.spindle_1.geometry} material={materials.SpindleStainlessSteel} material-emissive={jointColors[0] | jointColors[1] | jointColors[2]}>
            { tool }
            { holder }
          </mesh>
          <mesh geometry={nodes.spindle_2.geometry} material={materials.ClearAnnodizedAluminum} material-emissive={jointColors[0] | jointColors[1] | jointColors[2]}/>
          <mesh geometry={nodes.spindle_3.geometry} material={materials.SpindleBlack} material-emissive={jointColors[0] | jointColors[1] | jointColors[2]}/>
        </group>

        <mesh geometry={nodes.trunnion.geometry} material={materials.TrunnionMaterial} rotation-y={Math.PI/180*joints[3]} ref={trunnionRef} material-emissive={jointColors[3]}>
          <mesh geometry={nodes.mini90.geometry} material={materials.Mini90Material} rotation-z={Math.PI/180*joints[4]} ref={tableRef} material-emissive={jointColors[4]}>
            <group position={[ modelOffsets.X, modelOffsets.Y, modelOffsets.Z ]} rotation-y={ modelOffsets.B*Math.PI/180 } rotation-z={ modelOffsets.C*Math.PI/180 }>
              <group ref={partRef}></group>
              {model}
            </group>
            {children}
          </mesh>
        </mesh>
      </mesh>
    </group>
  )
}
