import { useEffect, useRef } from 'react';
import { useCameraControls, selectAddParent, selectRemoveParent, selectSetActiveParent } from './CameraControls';
import { useThree } from '@react-three/fiber';
import { DoubleSide } from 'three';

export const FLAT = "FLAT";
export const BALL = "BALL";
export const ENGRAVE = "ENGRAVE";

export default function SimpleTool(props) {
  const { scene } = useThree();
  const {
    diameter = .125,
    length = 10,
    loaded = false,
    type = FLAT
  } = props;

  const toolRef = useRef();
  const addParent = useCameraControls(selectAddParent);
  const removeParent = useCameraControls(selectRemoveParent);
  const setActiveParent = useCameraControls(selectSetActiveParent);

  useEffect(() => {
    const tool = { name: "Tool", object: toolRef.current };

    addParent(tool);
    setActiveParent(tool);

    return () => {
      removeParent(tool);
    };
  }, [ addParent, removeParent, toolRef, setActiveParent ]);

  return <group>
    <group ref={toolRef} position-z={loaded ? -length : 0}/>
    { type === FLAT ? 
    <mesh rotation-x={Math.PI/2} position-z={-length*.5} visible={loaded}>
      <cylinderGeometry args={[ diameter*.5, diameter*.5, length, 20, 1, false, 0, 2*Math.PI ]}/>
      <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
    </mesh> : null }
    { type === BALL ? 
    <mesh rotation-x={Math.PI/2} position-z={-length*.5+diameter*.5} visible={loaded}>
      <capsuleGeometry args={[ diameter*.5, length, 20, 20 ]}/>
      <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
    </mesh> : null }
    { type === ENGRAVE ? 
    <group>
      <mesh rotation-x={Math.PI/2} position-z={-(length-diameter)*.5} visible={loaded}>
        <cylinderGeometry args={[ diameter*.5, diameter*.5, length-diameter, 20, 1, true, 0, 2*Math.PI ]}/>
        <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
      </mesh>
      <mesh rotation-x={-Math.PI/2} position-z={-length+diameter*.5} visible={loaded}>
        <coneGeometry args={[ diameter*.5, diameter, 20, 1, true, 0, 2*Math.PI ]}/>
        <meshStandardMaterial metalness={.9} roughness={.232} color={0xaaaaaa} envMap={scene.envMap} envMapIntensity={2} side={DoubleSide}/>
      </mesh>
    </group>: null }
  </group>
};
