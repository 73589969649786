import * as v2 from './v2';
import V250 from '../components/viewer3d/V2-50';

export const toolLength = v2.toolLength;
export const computeDynamicWorkOffsets = v2.computeDynamicWorkOffsets;
export const toolOrientation = v2.toolOrientation;
export const workPieceSpace = v2.workPieceSpace;
export const forwardFiveAxis = v2.forwardFiveAxis;
export const inverseFiveAxis = v2.inverseFiveAxis;
export const kinematics = v2.kinematics;
export const limits = {
  extents: {
    max: [ 2.55, 2.55, 0.0, 135, 9999 ],
    min: [ -2.0, -2.5, -3.54, -25, -9999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ 1, 1, 1, 37.5, 37.5 ],
  accelerations: [ 10, 10, 10, 300, 300 ]
};
export const jointLabels = v2.jointLabels;
export const getLimitErrors = v2.getLimitErrors;
export const name = "V2-50";
export const Machine = V250;
