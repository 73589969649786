import { createActions } from 'redux-actions';

const actions = createActions({
  MACHINE_STATE: {
    MOTION: {
      OFF: undefined,
      RAPID: undefined,
      LINEAR: undefined,
      ARC_C_W: undefined,
      ARC_C_C_W: undefined,
      LOCAL_MOVE: undefined,
      SET_POSITION: undefined,
      NON_MOTION_COMMAND: undefined,
      G5X: {
        ENABLE_G54: undefined,
        ENABLE_G55: undefined,
        ENABLE_G56: undefined,
        ENABLE_G57: undefined,
        ENABLE_G58: undefined,
        ENABLE_G59: undefined,
        ENABLE_G59_1: undefined,
        ENABLE_G59_2: undefined,
        ENABLE_G59_3: undefined,
        COMPUTE_DYNAMIC_WORK_OFFSETS: undefined,
        SET_G5X_WORK_OFFSET: undefined
      },
      RESET_ROTARY: undefined
    }
  }
});

export default actions;
