import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import * as messages from '../gcode/interpreter-messages';
import { EPS } from '../constants';
//import produce from 'immer';

import Solo from '../components/viewer3d/Solo';

export const isSuperSecret = false;

export const toolLength = (toolLengthOffset) => {
  return 8+toolLengthOffset;
};

export const computeDynamicWorkOffsets = (workOffsets, position) => {
  const atZero = inverseFiveAxis(workOffsets, 0);
  const atCurrentOrientation = inverseFiveAxis({ X: atZero[0], Y: atZero[1], Z: atZero[2], B: position.B, C: position.C }, 0);
  return {
    X: atCurrentOrientation[0],
    Y: atCurrentOrientation[1],
    Z: atCurrentOrientation[2],
    B: 0,
    C: 0
  };
};

export const toolOrientation = (joints) => {
  const B = joints[3];
  const C = joints[4];

  const Brad = B*Math.PI/180;
  const Crad = C*Math.PI/180;

  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);
  const CC = Math.cos(Crad);
  const SC = Math.sin(Crad);

  return { X: -CC*SB, Y: SC*SB, Z: CB }; 
};

export const workPieceSpace = (joints, toolLengthOffset) => {
//  return forwardFiveAxis(produce(joints, (draft) => draft[2] = draft[2]+.004), toolLengthOffset);
  return forwardFiveAxis(joints, toolLengthOffset);
};

export const forwardFiveAxis = (joints, toolLengthOffset) => {
  const B = joints[3];
  const C = joints[4];

  const Brad = B*Math.PI/180;
  const Crad = C*Math.PI/180;

  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);
  const CC = Math.cos(Crad);
  const SC = Math.sin(Crad);

  const Lz = toolLengthOffset;

  const Px = joints[0];
  const Py = joints[1];
  const Pz = joints[2];

  const Qx = CC*CB*Px+SC*Py-CC*SB*(Pz-Lz);
  const Qy = -SC*CB*Px+CC*Py+SC*SB*(Pz-Lz);
  const Qz = SB*Px+CB*(Pz-Lz);

  return { 
    X: Qx, 
    Y: Qy, 
    Z: Qz, 
    A: 0,
    B: B, 
    C: C 
  };
};

export const inverseFiveAxis = (position, toolLengthOffset) => {
  const B = position.B;
  const C = position.C;

  const Brad = B*Math.PI/180;
  const Crad = C*Math.PI/180;

  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);
  const CC = Math.cos(Crad);
  const SC = Math.sin(Crad);

  const Lz = toolLengthOffset;

  const Qx = position.X;
  const Qy = position.Y;
  const Qz = position.Z;

  const Px = CC*CB*Qx-SC*CB*Qy+SB*Qz;
  const Py = SC*Qx+CC*Qy;
  const Pz = -CC*SB*Qx+SC*SB*Qy+CB*Qz+Lz;

  return [ Px, Py, Pz, B, C ];
};

export const kinematics = {
  inverseKinematics: (machineState, machineSpace) => {
    const kinematicsMode = machineState.kinematicsMode;

    let toolLengthOffset = 0;

    if(!machineSpace) {
      toolLengthOffset = machineState.tool.toolLengthOffset.Z;
    }

    const position = machineState.motion.position;

    if(kinematicsMode === FIVE_AXIS) {
      return inverseFiveAxis(position, toolLengthOffset);
    } else {
      return [ position.X, position.Y, position.Z+toolLengthOffset, position.B, position.C ];
    }
  },
  forwardKinematics: (machineState, machineSpace) => {
    const kinematicsMode = machineState.kinematicsMode;
    const toolLengthOffset = machineSpace ? 0 : machineState.tool.toolLengthOffset.Z;
    const joints = machineState.joints;

    if(kinematicsMode === FIVE_AXIS) {
      return forwardFiveAxis(joints, toolLengthOffset);
    } else {
      return { 
        X: joints[0], 
        Y: joints[1], 
        Z: joints[2]-toolLengthOffset, 
        A: 0,
        B: joints[3], 
        C: joints[4] 
      };
    }
  }
};

export const limits = {
  extents: {
    max: [ 6.623, 3.38785, .01, 145, 99999 ],
    min: [ -3.03136, -3.5136, -6.24, -145, -99999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ 9.84, 9.84, 9.84, 114.7, 114.7 ],
  accelerations: [ 656, 656, 656, 16000, 16000 ]
};

export const jointLabels = [ "X", "Y", "Z", "B", "C" ];

export const getLimitErrors = (joints, limits) => {
  const errors = [];
  for(var i = 0; i < joints.length; i++) {
    const joint = joints[i];
    if(joint < limits.extents.min[i]-EPS) {
      // suggestion for z
      // suggestion: "lengthen tool by " + Math.abs(joint-limits.extents.min[i])
      errors.push(messages.MinimumLimitError(jointLabels[i], Math.abs(joint-limits.extents.min[i])));
    } else if(joint > limits.extents.max[i]+EPS) {
      // suggestsion for z
      // suggestion: "shorten tool by " + Math.abs(joint-limits.extends.max[i])
      errors.push(messages.MaximumLimitError(jointLabels[i], Math.abs(joint-limits.extents.max[i])));
    }
  }

  return errors.length > 0 ? errors : [];
};

export const name = "Solo";
export const Machine = Solo;
