import { connect } from 'react-redux';

import { selectDemo } from '../selectors';
import actions from '../actions';
import App from '../components/App';
import machines from '../machines';
import { firstSetWorkCoordinateSystemMessage as selectFirstSetWorkCoordinateSystemMessage } from '../selectors/messages';
import * as spindleControlConstants from '../constants/machine-state/spindle-control';
import { FIRST_G5X_ENCOUNTERED, USER_CHOSEN_G5X } from '../constants/viewer3d';

export default connect(
  (state, ownProps) => {
    const modelOffsetType = state.viewer3d.modelOffsetType;

    let modelOffsets = { X: 0, Y: 0, Z: 0, A: 0, B: 0, C: 0 };

    if(modelOffsetType === FIRST_G5X_ENCOUNTERED) {
      const firstG5xMessage = selectFirstSetWorkCoordinateSystemMessage(state);
      if(firstG5xMessage) {
        modelOffsets = state.machineState.motion.g5x.offsets[firstG5xMessage.wcs];
      }
    } else if(modelOffsetType === USER_CHOSEN_G5X) {
      const modelOffsetIndex = state.viewer3d.modelOffsetIndex;
      modelOffsets = state.machineState.motion.g5x.offsets[modelOffsetIndex];
    }

    return {
      demo: selectDemo(state),
      hideTitleBar: !state.ui.titleBar.visible,
      machine: state.machineState.machine,
      running: state.interpreter.running,
      joints: state.machineState.joints,
      toolHolder: state.machineState.tool.table[state.machineState.tool.loaded].holder,
      toolLoaded: state.machineState.tool.loaded !== 0,
      toolLength: machines[state.machineState.machine].toolLength(state.machineState.tool.table[state.machineState.tool.loaded].Z),
      toolType: state.machineState.tool.table[state.machineState.tool.loaded].type,
      toolDiameter: 2*state.machineState.tool.table[state.machineState.tool.loaded].R,
      currentTime: state.interpreter.currentTime,
      modelScale: state.viewer3d.modelScale,
      modelColor: state.viewer3d.modelColor,
      modelOffsets,
      showModel: state.viewer3d.showModel,
      spindleAngle: state.machineState.spindleControl === spindleControlConstants.CCW ? 
                    state.interpreter.currentTime*2*Math.PI*state.machineState.spindleSpeed :
                    ((state.machineState.spindleControl === spindleControlConstants.CW) ?
                      -state.interpreter.currentTime*2*Math.PI*state.machineState.spindleSpeed : 0)
    }
  },
  (dispatch, ownProps) => ({
    onNextFeaturesSubStep: () => dispatch(actions.ui.demo.nextFeaturesSubStep()),
    onChangeMachine: (machine) => {
      dispatch(actions.machineState.machine.set(machine));
    },
  })
)(App);
