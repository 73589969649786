import actions from '../../actions/viewer3d';
import { handleActions } from 'redux-actions';
import { FIRST_G5X_ENCOUNTERED } from '../../constants/viewer3d';

import produce from 'immer';

export const defaultState = {
  showModel: false,
  modelLoaded: false,
  modelScale: 1./25.4,
  modelColor: 0xaaaaaa,
  modelOffsetType: FIRST_G5X_ENCOUNTERED,
  modelOffsetIndex: 1,

  showBetweenLines: true,
  showTimeSlice: false,
  showPastOnly: false,
  showFutureOnly: false,

  minLine: 0,
  maxLine: 999999999,
  automaticallyUpdateLines: true,
  isolateSlider: false,

  timeSlice: 10,

  rapidColor: 0x000080,
  feedColor: 0xffffff,

  offset: 0
};

const reducer = handleActions(
  {
    [actions.viewer3d.reset]: produce((state,action) => { return defaultState }),
    [actions.viewer3d.loadModel]: produce((state,action) => { state.modelLoaded = true }),
    [actions.viewer3d.showModel]: produce((state,action) => { state.showModel = true }),
    [actions.viewer3d.hideModel]: produce((state,action) => { state.showModel = false }),
    [actions.viewer3d.setModelScale]: produce((state,action) => { state.modelScale = action.payload }),
    [actions.viewer3d.setModelColor]: produce((state,action) => { state.modelColor = action.payload }),
    [actions.viewer3d.setModelOffsetType]: produce((state,action) => { state.modelOffsetType = action.payload }),
    [actions.viewer3d.setModelOffsetIndex]: produce((state,action) => { state.modelOffsetIndex = action.payload }),

    [actions.viewer3d.setShowBetweenLines]: produce((state,action) => { state.showBetweenLines = action.payload }),
    [actions.viewer3d.setShowTimeSlice]: produce((state,action) => { state.showTimeSlice = action.payload }),
    [actions.viewer3d.setShowPastOnly]: produce((state,action) => { state.showPastOnly = action.payload }),
    [actions.viewer3d.setShowFutureOnly]: produce((state,action) => { state.showFutureOnly = action.payload }),

    [actions.viewer3d.setMinLine]: produce((state,action) => { state.minLine = action.payload }),
    [actions.viewer3d.setMaxLine]: produce((state,action) => { state.maxLine = action.payload }),
    [actions.viewer3d.setAutomaticallyUpdateLines]: produce((state,action) => { state.automaticallyUpdateLines = action.payload }),
    [actions.viewer3d.setIsolateSlider]: produce((state,action) => { state.isolateSlider = action.payload }),
    [actions.viewer3d.setTimeSlice]: produce((state,action) => { state.timeSlice = action.payload }),

    [actions.viewer3d.setRapidColor]: produce((state,action) => { state.rapidColor = action.payload }),
    [actions.viewer3d.setFeedColor]: produce((state,action) => { state.feedColor = action.payload }),

    [actions.viewer3d.setOffset]: produce((state,action) => { state.offset = action.payload })
  }, defaultState
);

export default reducer;
