import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import { combineActions, handleActions } from 'redux-actions';

import { calculatePosition } from '../../util/motion-math';
import actions from '../../actions';

import feedRateMode from './feed-rate-mode';
import feedRate from './feed-rate';
import planeSelect from './plane-select';
import units from './units';
import tool from './tool';
import kinematicsMode from './kinematics-mode';
import cutterCompensation from './cutter-compensation';
import spindleSpeed from './spindle-speed';
import spindleControl from './spindle-control';
import machine from './machine';
import motion, { doForwardKinematics } from './motion';
import joints, { doInverseKinematics } from './joints';

import machines from '../../machines';

import GCodeInterpreter from '../../gcode/interpreter';

// fullState is the whole redux state tree after all regular actions have been reduced
export const machineStateWithState = (fullState) => handleActions({
  [combineActions(
    actions.interpreter.previousLine,
    actions.interpreter.nextLine,
    actions.interpreter.incrementTime,
    actions.interpreter.setCurrentLineWithPercentage,
    actions.interpreter.setCurrentLineWithPercentageLineBounded,
    actions.interpreter.setCurrentLineWithTime,
    actions.interpreter.setCurrentLine
  )]: (state, payload) => {
    const currentLine = fullState.interpreter.currentLine;
    const prevLine = currentLine-1;

    const linesPerIndex = fullState.interpreter.linesPerIndex;

    const totalStates = fullState.interpreter.states.length;

    if(totalStates > 0) {
      const index = Math.min(Math.floor(prevLine/linesPerIndex), totalStates-1);
      const closestPreviousState = index < 0 ? 
        fullState.interpreter.initialState :
        fullState.interpreter.states[index];
      const line = Math.max(index*linesPerIndex, -1);

      let prevState = closestPreviousState;

      const lines = fullState.interpreter.lines;
      let interpreter = null;
      let currentState = null;
      const currentIndex = Math.min(Math.floor(currentLine/linesPerIndex), totalStates-1);
      if(prevLine > line || currentIndex*linesPerIndex !== currentLine) {
        interpreter = new GCodeInterpreter({ machineState: prevState });

        let l = line;
        while(l <= prevLine) {
          prevState = interpreter.interpret(lines[l]);
          l++;
        }
      }

      if(currentIndex*linesPerIndex === currentLine) {
        // if the current line is in our list of indexed states, use it
        currentState = fullState.interpreter.states[currentIndex];
      } else {
        // otherwise, interpret from the previous state
        currentState = interpreter.interpret(lines[currentLine]);
      }

      const currentMachine = machines[currentState.machine];

// instead of getting the previous position like so:
//      const prevPos = prevState.get("motion").get("position");
// we must calculate forward kinematics using the current state in case there
// were any state changes in the current line that would result in a change to the work piece
// space such as a G43, G49, G60, etc.
      const prevJoints = prevState.joints;
      const prevPos = currentMachine.kinematics.forwardKinematics({ tool: currentState.tool, kinematicsMode: currentState.kinematicsMode, joints: prevJoints });
      const currentPos = currentState.motion.position;

      const times = fullState.interpreter.times;
      const prevTime = prevLine < 0 ? 0 : times[prevLine];
      const nextTime = times[currentLine];
      const currentTime = fullState.interpreter.currentTime;

      let t = 1;
      if(nextTime-prevTime > 0) {
        t = (currentTime-prevTime)/(nextTime-prevTime);
      }

      const motionMode = currentState.motion.mode;
      const turns = currentState.motion.turns;
      const planeSelect = currentState.planeSelect;
      const pos = calculatePosition(motionMode, prevPos, currentPos, t, planeSelect, turns, currentState.motion.issuedMotionCommand);

      let nextState = { ...currentState, motion: { ...currentState.motion, position: pos }};
      const joints = currentMachine.kinematics.inverseKinematics(nextState);

      nextState.joints = joints;

      return nextState;
    }

    return state;
  }
}, fullState.machineState);

const combinedReducer = combineReducers({
  machine,
  feedRateMode,
  feedRate,
  units,
  tool,
  planeSelect,
  kinematicsMode,
  cutterCompensation,
  spindleSpeed,
  spindleControl,
  motion,
  joints
});

const reducer = (state, action) => {
  if(action.type === actions.machineState.set.toString()) {
    return action.payload;
  } else {
    return combinedReducer(state, action);
  }
};

export default reduceReducers(
  reducer, 
  (state, action) => {
    const newJoints = doInverseKinematics(state)(state.joints, action);
    return { ...state, joints: newJoints };
  },
  (state, action) => {
    const newMotion = doForwardKinematics(state)(state.motion, action);
    return { ...state, motion: newMotion };
  }
);
