import { FIVE_AXIS } from '../constants/machine-state/kinematics-mode';
import * as messages from '../gcode/interpreter-messages';
import { EPS } from '../constants';
import V2 from '../components/viewer3d/V2';
//import produce from 'immer';

export const toolLength = (toolOffset) => {
  return 6.26+toolOffset;
};

export const computeDynamicWorkOffsets = (workOffsets, position) => {
  const atZero = inverseFiveAxis(workOffsets, 0);
  const atCurrentOrientation = inverseFiveAxis({ X: atZero[0], Y: atZero[1], Z: atZero[2], A: position.A, B: position.B }, 0);
  return {
    X: atCurrentOrientation[0],
    Y: atCurrentOrientation[1],
    Z: atCurrentOrientation[2],
    A: 0,
    B: 0
  };
};

export const toolOrientation = (joints) => {
  const A = joints[3];
  const B = joints[4];

  const Arad = A*Math.PI/180;
  const Brad = B*Math.PI/180;

  const CA = Math.cos(Arad);
  const SA = Math.sin(Arad);
  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);

  return { X: -SB*CA, Y: SA, Z: CB*CA }; 
};

export const workPieceSpace = (joints, toolLengthOffset) => {
//  return forwardFiveAxis(produce(joints, (draft) => draft[2] = draft[2]+.004), toolLengthOffset);
  return forwardFiveAxis(joints, toolLengthOffset);
};

export const forwardFiveAxis = (joints, toolLengthOffset) => {
  const A = joints[3];
  const B = joints[4];

  const Arad = A*Math.PI/180;
  const Brad = B*Math.PI/180;

  const CA = Math.cos(Arad);
  const SA = Math.sin(Arad);
  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);

  const Lz = toolLengthOffset;

  const Px = joints[0];
  const Py = joints[1];
  const Pz = joints[2];

  const Qx = Px*CB+Py*SB*SA-(Pz-Lz)*SB*CA;
  const Qy = Py*CA+(Pz-Lz)*SA;
  const Qz = Px*SB-Py*CB*SA+(Pz-Lz)*CB*CA;

  return { 
    X: Qx, 
    Y: Qy, 
    Z: Qz, 
    A: A, 
    B: B, 
    C: 0
  };
};

export const inverseFiveAxis = (position, toolLengthOffset) => {
  const A = position.A;
  const B = position.B;

  const Arad = A*Math.PI/180;
  const Brad = B*Math.PI/180;

  const CA = Math.cos(Arad);
  const SA = Math.sin(Arad);
  const CB = Math.cos(Brad);
  const SB = Math.sin(Brad);

  const Lz = toolLengthOffset;

  const Qx = position.X;
  const Qy = position.Y;
  const Qz = position.Z;

  const Px = Qx*CB+Qz*SB;
  const Py = Qx*SB*SA+Qy*CA-Qz*CB*SA;
  const Pz = -Qx*SB*CA+Qy*SA+Qz*CB*CA+Lz;

  return [ Px, Py, Pz, A, B ];
};

export const kinematics = {
  inverseKinematics: (machineState, machineSpace) => {
    const kinematicsMode = machineState.kinematicsMode;

    let toolLengthOffset = 0;

    if(!machineSpace) {
      toolLengthOffset = machineState.tool.toolLengthOffset.Z;
    }

    const position = machineState.motion.position;

    if(kinematicsMode === FIVE_AXIS) {
      return inverseFiveAxis(position, toolLengthOffset);
    } else {
      return [ position.X, position.Y, position.Z+toolLengthOffset, position.A, position.B ];
    }
  },
  forwardKinematics: (machineState, machineSpace) => {
    const kinematicsMode = machineState.kinematicsMode;
    const toolLengthOffset = machineSpace ? 0 : machineState.tool.toolLengthOffset.Z;
    const joints = machineState.joints;

    if(kinematicsMode === FIVE_AXIS) {
      return forwardFiveAxis(joints, toolLengthOffset);
    } else {
      return { 
        X: joints[0], 
        Y: joints[1], 
        Z: joints[2]-toolLengthOffset, 
        A: joints[3], 
        B: joints[4],
        C: 0
      };
    }
  }
};

export const limits = {
  extents: {
    max: [ 2.55, 2.55, 0.1, 135, 9999 ],
    min: [ -2.0, -2.5, -3.45, -25, -9999]
  },
  // x,y,z in inches/second
  // a,b in degrees/second
  velocities: [ 1, 1, 1, 37.5, 37.5 ],
  accelerations: [ 10, 10, 10, 300, 300 ]
};

export const jointLabels = [ "X", "Y", "Z", "A", "B" ];

export const getLimitErrors = (joints, limits) => {
  const errors = [];
  for(var i = 0; i < joints.length; i++) {
    const joint = joints[i];
    if(joint < limits.extents.min[i]-EPS) {
      // suggestion for z
      // suggestion: "lengthen tool by " + Math.abs(joint-limits.extents.min[i])
      errors.push(messages.MinimumLimitError(jointLabels[i], Math.abs(joint-limits.extents.min[i])));
    } else if(joint > limits.extents.max[i]+EPS) {
      // suggestsion for z
      // suggestion: "shorten tool by " + Math.abs(joint-limits.extends.max[i])
      errors.push(messages.MaximumLimitError(jointLabels[i], Math.abs(joint-limits.extents.max[i])));
    }
  }

  return errors.length > 0 ? errors : [];
};

export const name = "V2-10";
export const Machine = V2;
