import { useCallback } from 'react';

function color2int(color) {
  return parseInt(color.substring(1),16);
}

function int2color(i) {
  return "#" + (i.toString(16).padStart(6, '0'));
}

function ColorPicker(props) {
  const {
    value,
    onChange
  } = props;

  const handleChange = useCallback((e) => {
    if(onChange) {
      const newValue = color2int(e.target.value);
      onChange({
        value: newValue
      });
    }
  }, [ onChange ]);

  return <input type="color" value={int2color(value)} onChange={handleChange} style={ { border: 0, padding: 0, marginRight: "8px", marginLeft: "10px" }}/>
}

export default ColorPicker;
