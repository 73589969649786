import actions from '../../actions/machine-state/tool';
import { handleActions } from 'redux-actions';
import { NONE, LONG } from '../../constants/machine-state/tool';

// The set tool is the number last set using a T word
// The loaded tool is the last tool that was loaded using M6. M6 loads the last set tool.

const initialTool = { Z: 0, R: 0, holder: NONE };

const reducer = handleActions(
  {
    [actions.machineState.tool.set]: (state, action) => {
      if(action.payload >= 0) {
        const toolNumber = Math.min(action.payload, 55);
        return { ...state, set: toolNumber };
      } else {
        return state;
      }
    },
    [actions.machineState.tool.change]: (state, action) => ({ ...state, loaded: state.set }),
    [actions.machineState.tool.setToolLengthOffset]: (state, action) => {
      const { H } = action.payload;

      let toolIndex = state.loaded;
      if( H !== undefined && H > 0) {
        toolIndex = H;
      }

      toolIndex = Math.min(toolIndex, 55);

      const tool = state.table[toolIndex];

      return {
        ...state,
        toolLengthOffset: tool 
      };
    },
    [actions.machineState.tool.clearToolLengthOffset]: (state, action) => ({
      ...state,
      toolLengthOffset: initialTool
    }),
    [actions.machineState.tool.table.setTool]: (state, action) => {
      const { P: toolNumber, X, Y, Z, A, B, C, U, V, W, R, I, J, Q, holder, type } = action.payload;
      let params = { X, Y, Z, A, B, C, U, V, W, R, I, J, Q, holder, type };
      for(const key in params) {
        if(params[key] === undefined) {
          delete params[key];
        }
      }
      const table = [ ...state.table ];
      if(toolNumber > 0) {
        table[toolNumber] = {
          ...table[toolNumber],
          ...params
        };
      }

      return { 
        ...state,
        table 
      };
    }
  },
  {
    set: 0,
    loaded: 0,
    toolLengthOffset: initialTool,
    table: [
     { Z: 0, R: 0, holder: NONE },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
     { Z: -3, R: .0625, holder: LONG },
    ]
  }
);

export default reducer;
