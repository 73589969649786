import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import GCodeLoader from '../containers/GCodeLoader';
import ModelLoader from '../containers/ModelLoader';
import Progress from '../containers/Progress';
import { ReactComponent as LogoSvg } from "../logo.svg";
import HelpIcon from '@mui/icons-material/Help';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CopyEmbedWidget from './CopyEmbedWidget';
import CopyLinkWidget from './CopyLinkWidget';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PREFIX = 'TitleBar';

const classes = {
  videoPane: `${PREFIX}-videoPane`,
  appBar: `${PREFIX}-appBar`,
  appBarSmall: `${PREFIX}-appBarSmall`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`
};

const StyledContainer = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.videoPane}`]: {
    width: 900,
    height: 500
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1000,
    backgroundColor: "#333333"
  },

  [`& .${classes.appBarSmall}`]: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#333333",
    height: 64
  },

  [`& .${classes.title}`]: {
    flex: 1,
    color: "white",
    textDecoration: "none"
  },

  [`& .${classes.logo}`]: {
    height: 32,
    marginRight: 10
  }
}));

const TitleBar = ((props) => {
  const { 
    hideButtons,
    title
  } = props;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [ videoPaneOpen, setVideoPaneOpen ] = useState(false);
  const openVideoPane = useCallback(() => setVideoPaneOpen(true), [ setVideoPaneOpen ]);
  const closeVideoPane = useCallback(() => setVideoPaneOpen(false), [ setVideoPaneOpen ]);

  const [ sharePaneOpen, setSharePaneOpen ] = useState(false);
  const openSharePane = useCallback(() => setSharePaneOpen(true), [ setSharePaneOpen ]);
  const closeSharePane = useCallback(() => setSharePaneOpen(false), [ setSharePaneOpen ]);

  return (
    <StyledContainer>
    <AppBar className={isSmall ? classes.appBarSmall : classes.appBar}>
      <Toolbar>
        <a href="https://www.pentamachine.com" target="_blank" rel="noopener noreferrer">
          <LogoSvg height="50" alt="Penta" fill="white"/>
        </a>
        <Typography className={classes.title}>
        <a style={{ marginLeft: "16px" }} href="https://www.pentamachine.com" className={classes.title} target="_blank" rel="noopener noreferrer">{ title ? title : ( isSmall? "Simulator" : "Penta Simulator v" + process.env.REACT_APP_VERSION )  }</a> 
        </Typography>
        <Progress/>
        { hideButtons ? null :
        <>
        <GCodeLoader onError={(e) => console.error(e)}/>
        <ModelLoader size="small" color="inherit" binary={true} onError={(e) => console.error(e)}/>
        <Button onClick={openSharePane} color="inherit" size="small"><ShareIcon/></Button>
        <Button onClick={openVideoPane} color="inherit" size="small"><HelpIcon/></Button>
        <Dialog open={sharePaneOpen} onClose={closeSharePane} maxWidth="lg">
          <DialogTitle>Share</DialogTitle>
          <DialogContent>
          <CopyEmbedWidget/><br/>
          <CopyLinkWidget/><br/>
          </DialogContent>
        </Dialog>
        <Dialog open={videoPaneOpen} onClose={closeVideoPane} maxWidth="lg">
        <iframe title="Penta Simulator Tutorial" width="800" height="450" src="https://www.youtube.com/embed/EQxXC_ySR7k" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Dialog>
        </>
        }
      </Toolbar>
    </AppBar>
    </StyledContainer>
  );
})

export default TitleBar;
